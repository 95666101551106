import React, {CSSProperties} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./Header.scss";
import {Link, useHistory} from "react-router-dom";
import {logout} from "../../redux/slices/user-context";
import {userContextSelector} from "../../redux/selectors/user-context";
import {currentClientSelector} from "../../redux/selectors";
import Uploads from "../uploads/Uploads";

const smTextStyle: CSSProperties = {
  fontSize: '14px'
}

const Header = () => {
  //const [counter, setCounter] = useState(0);
  const context = useSelector(userContextSelector);
  const currentClient = useSelector(currentClientSelector);


  const dispatch = useDispatch();
  const history = useHistory();

  async function doLogout() {
    dispatch(logout());
    history.push("/");
  }

  let userInfo;
  if (context.context.is_authenticated) {
    userInfo = <>
      <Uploads />
      {currentClient.name == null && <span style={smTextStyle}>No API client selected</span>}
      {currentClient.name != null && <span style={smTextStyle}>Using: {currentClient.name}</span>}
      |<span style={smTextStyle}>{context.context.email}</span>
      <button type={"button"} onClick={doLogout}>Logout</button>
    </>
  } else {
    userInfo = <>
      <Link to={"/login"}>Login</Link>
    </>
  }

  return (
    <header className="Header">
      <div className="nav">
        <Link to="/">Mitosys</Link> |
        <Link to="/api-clients">API Clients</Link>
        {/* Only show these links if an api client is selected */}
        {currentClient.public_key != null && <>
          <Link to="/studies">Studies</Link>
          <Link to="/upload">Upload</Link>
        </>}
      </div>
      <div className="user">
        {userInfo}
      </div>
    </header>
  );

}

export default Header;
