import React, {useEffect, useState} from 'react';
import './App.scss';
import Header from "../components/header/Header";
import {ChakraProvider} from "@chakra-ui/react";
import {Helmet} from "react-helmet";
import AppRouter from "./AppRouter";
import nProgress from "nprogress";
import {useDispatch, useSelector} from "react-redux";
import {userContextSelector} from "../redux/selectors/user-context";
import {dataInit} from "../redux/thunks/dataInit";

nProgress.configure({ showSpinner: false });


function App() {

  const userContext = useSelector(userContextSelector);
  const dispatch = useDispatch();
  const [inited, setInited] = useState(false);

  async function init() {
    await dispatch(dataInit());
    setInited(true);
  }

  useEffect(() => {
    init();
  }, [userContext]);


  return (
    <ChakraProvider>
      <div className="App">

        <Helmet titleTemplate={"%s · Mitosys"} defaultTitle={"Mitosys"} />

        {inited && <>
          <Header/>

          <main>
            <AppRouter/>
          </main>
        </> }

        {/*<div className="footer">footer</div>*/}

      </div>
    </ChakraProvider>
  );
}

export default App;
