import React from 'react';
import {hydrate, render} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

import App from './containers/App';
import {Provider} from "react-redux";
import axios from "axios";
import {fetchUserData} from "./redux/slices/user-context";
import {store} from "./client-store";


axios.defaults.baseURL = "/api";
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'x-csrftoken';

store.dispatch(fetchUserData()).then(() => {
  render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    document.getElementById('root')
  );
});

// Load all components needed before rendering
/*loadableReady().then(() => {
hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
});*/

if (module.hot) {
  module.hot.accept('./containers/App', () => {
    hydrate(
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>,
      document.getElementById('root')
    );
  });
}
