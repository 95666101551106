import {useSelector} from "react-redux";
import {userContextSelector} from "../redux/selectors/user-context";
import {Redirect, Route, useLocation} from "react-router-dom";
import React from "react";

export function LoggedInOnlyRoute({ children, ...rest }) {
  const userContext = useSelector(userContextSelector);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userContext.context.is_authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: `?from=${location.pathname}`
            }}
          />
        )
      }
    />
  );
}

export const loggedInOnly = (WrappedComponent: React.ElementType) => {

  return ({ ...props }) => {
    const userContext = useSelector(userContextSelector);
    const location = useLocation();

    return userContext.context.is_authenticated ?
      <WrappedComponent {...props} /> :
      <Redirect to={{pathname: "/login", search: `?from=${location.pathname}`}}/>
  }
}


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function LoggedOutOnlyRoute ({ children, ...props }) {
  const userContext = useSelector(userContextSelector);
  let query = useQuery();

  return <Route {...props} render={
    ({location}) =>
      !userContext.context.is_authenticated ?
        children
        : <Redirect to={query.get('from') ?? "/"} />

  } />
}

export const loggedOutOnly = Comp => ({ ...props }) => {
  const userContext = useSelector(userContextSelector);
  let query = useQuery();

  return !userContext.context.is_authenticated ?
    <Comp {...props} /> :
    <Redirect to={query.get('from') ?? "/"} />
}

