import {createAsyncThunk} from "@reduxjs/toolkit";
import {ThunkApi} from "../slices/types";
import {fetchApiClients} from "../slices/api-clients";
import {RootState} from "../slices";
import {setCurrentClient} from "../slices/currentApiClient";

export const dataInit = createAsyncThunk<void, void, ThunkApi>('dataInit', async (_, api) => {
  await api.dispatch(fetchApiClients());
  const savedCurrentPk = window.localStorage.getItem("client");
  if (savedCurrentPk != null) {
    const state = api.getState() as RootState;
    const savedClient = state.apiClients.apiClients.find(c => c.public_key === savedCurrentPk);
    if (savedCurrentPk != null) {
      api.dispatch(setCurrentClient(savedClient));
    }
  }
});
