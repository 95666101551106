import {loggedInOnly} from "../components/ProtectedRoutes";
import React, {useEffect, useRef} from "react";

const useComponentWillMount = (func) => {
  const willMount = useRef(true)

  if (willMount.current) func()

  willMount.current = false
}

function Secret() {

  useComponentWillMount(() => {
    //console.log("Secret component will mount");
  });

  useEffect(() => {
    //console.log("Secret component mounted");
  }, []);

  return <h1>Secret!</h1>;
}

export default loggedInOnly(Secret);
