import {createAction, createSlice} from "@reduxjs/toolkit";

export interface LoadSlice {
  loadingData: boolean;
  loadingComponent: boolean;
}

export const setLoadingData = createAction<boolean>('load/loadingData') ;
export const setLoadingComponent = createAction<boolean>('load/loadingData') ;

const loadSlice = createSlice({
  name: 'load',

  initialState: {
    loadingComponent: false,
    loadingData: false
  } as LoadSlice,

  reducers: {

  }
});

export default loadSlice.reducer;
