import {createAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./index";
import {ThunkApi} from "./types";
import {fetchStudies} from "./studies";

export interface StudyUpload {
  state: 'pending' | 'uploading' | 'processing' | 'completed' | 'failed';
  error?: any;
  uploadProgress: number[];
  //processingProgress: number;
  //totalProgress: number;
  numFiles: number;
  id: string;
  title: string;
}

export interface StudyUploadSlice {
  studies: StudyUpload[];
}

export interface StudyUploadCreate {
  numFiles: number;
  title: string
  id: string;
}

export interface ProgressUpdateDto {
  studyId: string;
  file: number;
  progress: number;
}

const updateProgress = createAction<ProgressUpdateDto>('studyUpload/updateProgress');
const completeUpload = createAction<string>('studyUpload/completeUpload');

export const createUpload = createAsyncThunk<StudyUpload, StudyUploadCreate>('studyUpload/create', (dto, thunkAPI) => {
  const progress = Array.from(Array(dto.numFiles)).map(() => 0);
  return {
    id: dto.id,
    state: 'pending',
    error: null,
    numFiles: dto.numFiles,
    uploadProgress: progress,
    title: dto.title
  }
});

export const reportProgress = createAsyncThunk<void, ProgressUpdateDto, ThunkApi>('studyUpload/reportProgress', async (dto, thunkApi) => {
  thunkApi.dispatch(updateProgress(dto));
  // check for upload completion
  const state = thunkApi.getState() as RootState;
  const idx = state.studyUpload.studies.findIndex(s => s.id === dto.studyId);
  const prog = state.studyUpload.studies[idx];
  if (prog.state === 'completed') {
    return;
  }
  if (prog.uploadProgress.every(n => n === 1)) {
    thunkApi.dispatch(completeUpload(dto.studyId));
    await thunkApi.extra.post(`/dicom/upload/${dto.studyId}/finish`, {});
    await thunkApi.dispatch(fetchStudies());
  }
});


const studyUploadSlice = createSlice({
  name: 'studyUpload',
  initialState: { studies: [] } as StudyUploadSlice,
  reducers: {
    updateProgress: (state, action: PayloadAction<ProgressUpdateDto>) => {
      const idx = state.studies.findIndex(s => s.id === action.payload.studyId);
      state.studies[idx].uploadProgress[action.payload.file] = action.payload.progress;
      state.studies[idx].state = 'uploading';
    },
    completeUpload: (state, action) => {
      const idx = state.studies.findIndex(s => s.id === action.payload);
      state.studies[idx].state = 'completed';
    }
  },
  extraReducers: builder => builder.addCase(createUpload.fulfilled, (state, action) => {
    state.studies.push(action.payload);
  })
});

export default studyUploadSlice.reducer;
