import React, {useEffect, useState} from "react";
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Progress
} from "@chakra-ui/react";
import {AiOutlineCloudUpload} from "react-icons/ai";
import "./Uploads.scss";
import {useSelector} from "react-redux";
import {totalProgressSelector, uploadProgressSelectorFactory, uploadsSelector} from "../../redux/selectors";

function ProgressIcon() {

  const progress = useSelector(totalProgressSelector);

  return <span style={{display: 'inline-block', position: 'relative', width: '40px', height: '40px', margin: 0}}>
    <AiOutlineCloudUpload size={24} style={{position: "relative", top: 8, left: 8}} />
    <div className="upload-progress"
         style={{position: "absolute", left: 4, bottom: 4, height: 2, backgroundColor: '#000', width: (progress) * 32}} />
  </span>
}

function UploadItem({ item }) {

  const [statusText, setStatusText] = useState('pending');
  const progress = useSelector(uploadProgressSelectorFactory(item.id));

  useEffect(() => {
    switch (item.state) {
      case 'pending':
        setStatusText('preparing');
        break;
      case 'uploading':
        setStatusText('uploading');
        break;
      case 'completed':
        setStatusText('completed');
        break;
      default:
        setStatusText('error');
    }
  }, [item]);

  return <div className="UploadItem">
    <p>{ item.title } <span className="small">{ statusText }</span></p>
    <Progress size={'xs'} isIndeterminate={item.state === 'pending'} value={progress * 100} colorScheme={(item.state === 'completed') ? 'green' : 'blue'} />
  </div>
}

function Uploads() {
  const uploads = useSelector(uploadsSelector);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, [uploads]);

  return (uploads.studies.length > 0) ? <Popover isOpen={show} autoFocus={false}>
    <PopoverTrigger>
      <IconButton variant="ghost" aria-label="Uploads" icon={<ProgressIcon />} onClick={() => setShow(!show)} />
    </PopoverTrigger>
    <PopoverContent tabIndex={-1}>
      <PopoverArrow />
      <PopoverBody>
        { uploads.studies.map(s => <UploadItem item={s} key={s.id} />) }
      </PopoverBody>
    </PopoverContent>
  </Popover> : <></>


}

export default Uploads;
