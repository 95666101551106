import {createAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiClientDto} from "./api-clients";
import axios from "axios";

export const setCurrentClient = createAction<ApiClientDto>('currentClient/set');
export const clearCurrentClient = createAction<ApiClientDto>('currentClient/clear');

const emptyClient: ApiClientDto = {
  public_key: null,
  secret_key: null,
  name: null,
  last_used: null,
  created: null
}

const currentClientSlice = createSlice({
  name: 'currentClient',
  initialState: emptyClient,
  reducers: {
    set: (state, action: PayloadAction<ApiClientDto>) => {
      window.localStorage.setItem("client", action.payload.public_key);
      console.info('Setting axios headers');
      axios.defaults.headers['Authorization'] = `Bearer ${action.payload.secret_key}`;
      return action.payload;
    },
    clear: () => {
      window.localStorage.removeItem("client");
      axios.defaults.headers['Authorization'] = undefined;
      return emptyClient;
    }
  }
});

export default currentClientSlice.reducer;
