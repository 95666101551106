import {createAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ThunkApi} from "./types";
import {clearCurrentClient} from "./currentApiClient";


export interface UserContext {
  csrf: string;
  is_authenticated: boolean;
  email?: string;
  is_staff?: boolean;
  //oidc_login_url?: string;
  //oidc_logout_url?: string;
}

export interface UserContextSlice {
  context: UserContext;
  status: 'loading' | 'idle' | 'logging_in';
  lastUpdated?: number;
  lastError: any;
}

export interface LoginDto {
  email: string;
  password: string;
}

export const fetchUserData = createAsyncThunk<UserContext, void, ThunkApi>('userContext/fetch', async (_, api) => {
  const response = await api.extra.get<UserContext>('/context');
  return response.data;
});

export const login = createAsyncThunk<UserContext, LoginDto, ThunkApi>('userContext/login', async (dto, api) => {
  try {
    const res = await api.extra.post<UserContext>('/auth/login', dto);
    return res.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

export const logout = createAsyncThunk<UserContext, void, ThunkApi>('userContext/logout', async (_, api) => {
  const res = await api.extra.post<UserContext>('/auth/logout');
  api.dispatch(clearCurrentClient());
  return res.data;
});

export const setUserContext = createAction<UserContext | null>('userContext/set');

const userContextSlice = createSlice({
  name: 'userContext',

  initialState: {
    context: {
      csrf: 'NOTPROVIDED',
      is_authenticated: false,
    },
    //lastUpdated: null,
    status: 'idle',
    lastError: null
  } as UserContextSlice,

  reducers: {
    set(state, action: PayloadAction<UserContext>) {
      state.status = 'idle';
      state.lastUpdated = Date.now();
      state.lastError = null;
      state.context = action.payload;
    }
  },

  extraReducers: builder => {
    builder.addCase(fetchUserData.pending, (state, action) => {
      state.status = 'loading';
      state.lastError = null;
    }).addCase(fetchUserData.fulfilled, (state, action) => {
      state.status = 'idle';
      state.lastUpdated = Date.now()
      state.lastError = null;
      state.context = action.payload;
    }).addCase(fetchUserData.rejected, (state, action) => {
      state.status = 'idle';
      state.lastError = action.error;
    }).addCase(login.pending, state => {
      state.status = 'logging_in';
      state.lastError = null;
    }).addCase(login.fulfilled, (state, action) => {
      state.status = 'idle';
      state.lastUpdated = Date.now()
      state.lastError = null;
      state.context = action.payload;
    }).addCase(login.rejected, (state, action) => {
      state.status = 'idle';
      state.lastError = action.payload ?? action.error;
    }).addCase(logout.fulfilled, (state, action) => {
      state.status = 'idle';
      state.lastUpdated = Date.now();
      state.lastError = null;
      state.context = action.payload;
    });
  }
});


export default userContextSlice.reducer;
