import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../slices";

export const loadSelector = createSelector((state: RootState) => state.load, state => state);

export const apiClientsSelector = createSelector((state: RootState) => state.apiClients, s => s);
export const apiClientSelector = createSelector(
  (state: RootState, props) => state.apiClients.apiClients.find(c => c.public_key === props.pk),
    s => s
);
export const apiClientsIsRefreshingSelector = createSelector((state: RootState) => state.apiClients.status === 'loading', c => c);
export const currentClientSelector = createSelector((state: RootState) => state.currentClient, c => c);

export const studiesSelector = createSelector((state: RootState) => state.studies, c => c);
export const isStudiesLoadingSelector = createSelector(studiesSelector, c => c.status === 'loading');

export const uploadsSelector = createSelector((state: RootState) => state.studyUpload, c => c);
export const uploadProgressSelectorFactory = uploadId => createSelector(uploadsSelector, c => {
  const study = c.studies.find(s => s.id === uploadId);
  return study.uploadProgress.reduce((p, c) => p + c, 0) / study.numFiles;
});
export const totalProgressSelector = createSelector(uploadsSelector, c => {
  return c.studies.reduce((prev, cur) => {
    return prev + (cur.uploadProgress.reduce((p, c) => p + c, 0) / cur.numFiles);
  }, 0) / c.studies.length;
})
