import {createAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ThunkApi} from "./types";

export interface ApiClientDto {
  name: string;
  public_key: string;
  secret_key?: string;
  created: string;
  last_used: string;
}

export interface ApiClientsSlice {
  status: 'loading' | 'idle';
  lastError?: any;
  lastUpdated?: number;
  apiClients: ApiClientDto[];
}

export interface SetSecretKeyPayload {
  public_key: string;
  secret_key: string;
}

export const fetchApiClients = createAsyncThunk<ApiClientDto[], void, ThunkApi>('apiClients/fetch', async (_, api) => {
  const res = await api.extra.get<ApiClientDto[]>('/api-clients/');
  return res.data;
});

export const setApiClientSecretKey = createAction<SetSecretKeyPayload>('apiClients/setSecretKey');

const apiClientsSlice = createSlice({
  name: 'apiClients',
  initialState: {
    status: 'idle',
    apiClients: null,
    lastError: null,
    lastUpdated: null
  } as ApiClientsSlice,
  reducers: {
    setSecretKey: (state, action: PayloadAction<SetSecretKeyPayload>) => {
      state.apiClients.find(c => c.public_key === action.payload.public_key).secret_key = action.payload.secret_key;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchApiClients.pending, state =>  {
      state.status = 'loading';
    }).addCase(fetchApiClients.fulfilled, (state, action) =>  {
      state.lastError = null;
      state.lastUpdated = Date.now();
      state.status = 'idle';
      state.apiClients = action.payload;
    }).addCase(fetchApiClients.rejected, (state, action) => {
      state.status = 'idle';
      state.lastError = action.error;
    });
  }

});

export default apiClientsSlice.reducer
