import {configureStore as configure} from "@reduxjs/toolkit";

import logger from 'redux-logger';
import {rootReducer} from "./slices";
import axios, {AxiosInstance} from "axios";

/*export const store = configureStore({
    reducer: rootReducer,
    middleware: m => m().concat(logger)
});*/

const configureStore = (preloadedState, axiosInstance: AxiosInstance = axios, withLogger = false) => {

  const store = configure({
    reducer: rootReducer,
    middleware: m => {
      const defaultMiddleware = m({thunk: {extraArgument: axiosInstance}});
      return withLogger ? defaultMiddleware.concat(logger) : defaultMiddleware
    },
    preloadedState: preloadedState
  });

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./slices', () => {
      const nextRootReducer = require('./slices').rootReducer;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
