import {combineReducers} from "@reduxjs/toolkit";
import userContextReducer from "./user-context";
import loadReducer from "./load";
import apiClientReducer from "./api-clients";
import currentApiClient from "./currentApiClient";
import studyUpload from "./study-upload";
import studies from "./studies";

export const rootReducer = combineReducers({
  userContext: userContextReducer,
  load: loadReducer,
  apiClients: apiClientReducer,
  currentClient: currentApiClient,
  studyUpload,
  studies
});

export type RootState = ReturnType<typeof rootReducer>;
