import {Route, useLocation} from "react-router-dom";
import Home from "./home/Home";
import React, {useEffect, useState} from "react";
import loadable, {LoadableComponent} from "@loadable/component";
import Secret from "./Secret";
import {matchRoutes, renderRoutes, RouteConfig} from "react-router-config";
import nProgress from "nprogress";
import NotFound from "./non-ideal/NotFound";

const sleep = (ms) => new Promise<void>((r) => setTimeout(() => {
  console.log("slept for " + ms + " ms");
  r();
}, ms));

const Login = loadable(() => import('./login/Login'), {
  fallback: <p>Loading...</p>,
});
const ApiClients = loadable(() => import('./api-clients/ApiClients'));
const UploadStudy = loadable(() => import('./upload-study/UploadStudy'));
const Studies = loadable(() => import('./studies/Studies'));
const ViewStudy = loadable(() => import('./view-study/ViewStudy'));

type ResolverFactory = () => Promise<any>;

interface AppRoutesConfig extends RouteConfig {
  resolver?: ResolverFactory;
}

const routes: AppRoutesConfig[] = [
  {
    path: '/',
    exact: true,
    component: Home
  }, {
    path: '/login',
    component: Login
  }, {
    path: '/api-clients',
    component: ApiClients
  }, {
    path: '/upload',
    component: UploadStudy
  }, {
    path: '/studies',
    component: Studies
  }, {
    path: '/view-study/:id',
    component: ViewStudy
  }, {
    path: '/secret',
    component: Secret,
    //resolver: () => sleep(1000)
  }, {
    path: '*',
    component: NotFound
  }
];

export default function AppRouter() {

  const location = useLocation();
  const [renderLocation, setRenderLocation] = useState(location);

  useEffect(() => {

  }, []);

  async function handleLocationChange() {
    console.log("location changed");
    nProgress.start();
    const branch = matchRoutes(routes, location.pathname);
    const loadables: LoadableComponent<any>[] = [];
    const resolvers: ResolverFactory[] = [];
    branch.forEach(match => {
      // Detect if the component is a loadable
      if (typeof (match.route.component as LoadableComponent<any>).load === 'function') {
        console.log(`Loadable: ${match.route.component}`);
        loadables.push(match.route.component as LoadableComponent<any>);
      }
      // Collect the branch resolvers
      if (match.route.resolver != null) {
        resolvers.push(match.route.resolver);
      }
    });
    // Wait for the stuff
    await Promise.all([
      Promise.all(resolvers.map(r => r())).then(() => nProgress.inc()),
      Promise.all(loadables.map(l => l.load())).then(() => nProgress.inc())
    ]);
    setRenderLocation(location);
    nProgress.done();
  }

  useEffect(() => {

    if (location !== renderLocation) {
      handleLocationChange();
    }

    //setRenderLocation(location);
  }, [location]);

  return <Route location={renderLocation} render={({staticContext}) => {
    if (staticContext) staticContext.statusCode = 404;
    return renderRoutes(routes)
  }}>

  </Route>
}
