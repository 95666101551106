import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Paginated, ThunkApi} from "./types";

export interface Study {
  title: string;
  status: 'available' | 'created' | 'processing';
  size: number;
  series: number;
  instances: number;
  modality: string;
  created: string;
  id: string;
}

export interface StudiesSlice {
  studies: Paginated<Study>;
  status: 'idle' | 'loading';
  lastError: any;
}

export const fetchStudies = createAsyncThunk<Paginated<Study>, void, ThunkApi>('studies/fetch', async (_, api) => {
  const res = await api.extra.get<Paginated<Study>>('/dicom/studies');
  return res.data;
});

const studiesSlice = createSlice({
  name: 'studies',
  initialState: {
    status: "idle",
    studies: null,
    lastError: null
  } as StudiesSlice,

  reducers: {},
  extraReducers: builder => builder.addCase(fetchStudies.pending, state => {
    state.status = 'loading';
  }).addCase(fetchStudies.fulfilled, (state, action) => {
    state.lastError = null;
    state.status = 'idle';
    state.studies = action.payload;
  }).addCase(fetchStudies.rejected, (state, action) => {
    state.status = 'idle';
    state.lastError = action.error;
  })
});

export default studiesSlice.reducer;
